import { Fragment } from 'react'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { FaInstagram , FaWhatsapp, FaTwitter, FaFacebook, FaLinkedin} from 'react-icons/fa'

import Warp from '../assets/images/Warp.png'
import '../assets/css/Navbar.css'

const navigation = [
  { name: 'Home', href: '/', current: true },
  { name: 'Services', href: '/services', current: false },
  { name: 'About Us', href: '/about-us', current: false },


]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  return (
    <>
      <div className="logo-banner-wrapper">
        
        <span>
        <div class="flex  sm:justify-center align-center sm:mt-0">
              <a href="https://www.facebook.com/people/Webxus-Software-Development/61560544981065/" class="text-gray-500  dark:hover:text-white">
                    <FaFacebook/>
                  <span class="sr-only">Facebook page</span>
              </a>
             
           
              <a href="https://www.instagram.com/webxuss/" class="text-gray-500  dark:hover:text-white ms-5">
                  <FaInstagram />
                  <span class="sr-only">Instagram</span>
              </a>
              <a href="https://www.linkedin.com/company/webxus-software/" class="text-gray-500  dark:hover:text-white ms-5">
                  <FaLinkedin/>
                  <span class="sr-only">LinkedIn</span>
              </a>
             
          </div>
        </span>

        <span className='logo-banner-phone'><a href="mailto:info@webxuss.com">info@webxuss.com</a></span>
      </div>
      <Disclosure as="nav" className="bg-white border-gray-200 dark:bg-gray-900" >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8" >
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </DisclosureButton>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src={Warp}
                      alt="Your Company"
                      style={{ borderRadius: '50%' }}
                    />
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current ? 'bg-gray-900 custom-gradient-text font-bold' : 'custom-gradient-text hover:bg-gray-300 hover:custom-gradient-text',
                            'rounded-md px-3 py-2 text-sm font-bold'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <a class="bg-blue-500 hover:bg-blue-700 text-white text-sm  py-2 px-4 rounded gradient-bg-button" href='/contact-us'>
                    Get in Touch
                  </a>
                  {/* Profile dropdown */}

                </div>
              </div>
            </div>

            <DisclosurePanel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <DisclosureButton
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 font-bold custom-gradient-text' : 'text-gray-300 hover:bg-gray-700  font-bold custom-gradient-text',
                      'block rounded-md px-3 py-2 text-base '
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </DisclosureButton>
                ))}
              </div>
            </DisclosurePanel>
          </>
        )}
      </Disclosure>

    </>
  )
}
