import React from 'react'
import Form from '../components/Form.js'

function Contact() {
  return (
    <>
        <Form/>
        
    </>
  )
}

export default Contact